export type singleShowTypeNoTitle = {
    details: {
        fullName: string
        SE?: string
        release: number
        order: number
        cover: any
        accent: string
    }
}
export type singleShowType = {
    title: string
} & singleShowTypeNoTitle

export type multiShowType = {
    title: string,
    shows: singleShowTypeNoTitle[]
}

type edgesType = {
    node: {
        details: {
            SE?: string,
            fullName: string,
            order: number,
            release: number,
            title: string
            cover: any
            accent: string
        }
    }
}[]

const showsReduce = (edges: edgesType) => {
    return edges.reduce((p: (singleShowType | multiShowType)[], c) => {
        const { title, ...details } = c.node.details
        const lastTitle = p.at(-1)?.title
    
        // If it's a continuation of a serie or a new serie
        if (c.node.details.SE || title == lastTitle) {
            if (title == lastTitle) {
                // It's a continuation
                const item: singleShowTypeNoTitle = {
                    details
                }

                { (p[p.length - 1] as multiShowType).shows.push(item) }
            } else {
                // Is a first of a serie, make it multi
                const multiItem: multiShowType = {
                    title,
                    shows: [
                        c.node
                    ]
                }

                p.push(multiItem)
            }

        } else {
            // It's just a movie
            p.push({
                title,
                details,
            })
        }

        return p
    }, [])
}

export default showsReduce;