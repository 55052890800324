import { ChevronDownIcon } from "@chakra-ui/icons"
import { Box, Center, HStack, IconButton, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Portal, Square, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { multiShowType } from "../../utils/showsReduce"
import Shell from "../shell"
import ImageDisplay from "./imageDisplay"
import ShowHeading from "./showHeading"
import { ShowShell } from "./showList"

const pSBCr = (d: any) => {
    let x: any = {};

    d = parseInt(d.slice(1), 16) as unknown as string;
    x.r = d >> 16, x.g = d >> 8 & 255, x.b = d & 255

    return x
};
const shadeColor = (p: number, c0: string) => {
    let r, g, b, P, f, t, m = Math.round
    if (typeof (p) != "number" || p < -1 || p > 1 || typeof (c0) != "string" || (c0[0] != 'r' && c0[0] != '#')) return null;


    f = pSBCr(c0)
    P = p < 0
    t = P ? { r: 0, g: 0, b: 0 } : { r: 255, g: 255, b: 255 }
    p = P ? p * -1 : p
    P = 1 - p;

    if (!f || !t) return null;

    r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)
    g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)
    b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5)

    return "#" + (4294967296 + r * 16777216 + g * 65536 + b * 256).toString(16).slice(1, -2)
}

type EpisodeProps = {
    se: string
    title: string
}
const Episode = ({ se, title }: EpisodeProps) => {
    return (
        <Shell as={HStack} bgColor='blackAlpha.300' w='full'>
            <Shell as={Center} bgColor='blackAlpha.300'>
                <Text fontWeight='semibold'>{se}</Text>
            </Shell>
            <Text textTransform='capitalize'>{title}</Text>
        </Shell>
    )
}

type SerieProps = {
    serie: multiShowType
    index: number
}
const Serie = ({ serie, index }: SerieProps) => {
    const { cover, accent: color } = serie.shows[0].details
    const darkerColor = shadeColor(-.5, color)!

    return (
        <ShowShell index={index}>
            <ImageDisplay name={serie.title} image={cover} color={color} />
            <ShowHeading title={serie.title} />

            <Popover
                placement="bottom"
            >
                <PopoverTrigger>
                    <IconButton
                        aria-label="Show episodes"
                        icon={<ChevronDownIcon h={7} w={7} />}
                        w='full'
                        bgColor={darkerColor}
                        _hover={{ bgColor: color }}
                    />
                </PopoverTrigger>
                <Portal>
                    <PopoverContent
                        bg={darkerColor}
                        borderColor={darkerColor}
                        maxWidth='80vw'
                        width='unset'
                    >
                        <PopoverArrow bg={darkerColor} />
                        <PopoverCloseButton />
                        <PopoverHeader
                            border='0'
                            fontWeight='bold'
                            fontSize='2xl'
                        >
                            Episodes
                        </PopoverHeader>
                        <PopoverBody>
                            <Shell as={VStack} w='full'>
                                {serie.shows.map((ep, i) => {
                                    return (
                                        <Episode key={i} se={ep.details.SE!} title={ep.details.fullName} />
                                    )
                                })}
                            </Shell>
                        </PopoverBody>
                    </PopoverContent>
                </Portal>
            </Popover>
        </ShowShell>
    )
}

export default Serie