import { Text } from "@chakra-ui/react"
import React from "react"
import { singleShowType } from "../../utils/showsReduce"
import ImageDisplay from "./imageDisplay"
import { ShowShell } from "./showList"
import ShowHeading from "./showHeading"

type MovieProps = {
    movie: singleShowType
    index: number
}
const Movie = ({ movie, index }: MovieProps) => {
    return (
        <ShowShell index={index}>
            <ImageDisplay name={movie.title} image={movie.details.cover} color={movie.details.accent} />
            <ShowHeading title={movie.title} />
            <Text>{movie.details.release}</Text>
        </ShowShell>
    )
}

export default Movie