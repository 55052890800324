import * as React from "react"
import Head from "../components/Head"
import Layout from "../components/layout"
import ShowList from "../components/shows/showList"

const IndexPage = () => {
    return (
        <Layout>
            <Head />
            <ShowList />
        </Layout>
    )
}

export default IndexPage