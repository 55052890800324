import { Box } from "@chakra-ui/react"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import Tilt from 'react-parallax-tilt'
import Shell from "../shell"

type ImageDisplayProps = {
    name: string,
    color: string,
    image: IGatsbyImageData
}
const ImageDisplay = ({ name, color, image }: ImageDisplayProps) => {
    const img = getImage(image)!

    return (
        <Shell
            as={Tilt}
            bgGradient='radial(gray.700, gray.900)'
            glareEnable={true}
            glareMaxOpacity={.8}
            glareColor={color}
            glarePosition='all'
            glareBorderRadius="var(--chakra-radii-lg)"
            sx={{
                transformStyle: 'preserve-3d',
                touchAction: 'none'
            }}
            p={10}
            shadow={'base'}
            
        >
            <Box w='full' transform='translateZ(100px)' sx={{
                ".gatsby-image-wrapper": {
                    filter: `drop-shadow(0 0 3px black)`,
                }
            }} maxW='400px'>
                <GatsbyImage image={img} alt='' />
            </Box>
        </Shell>
    )
}

export default ImageDisplay