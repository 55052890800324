import { graphql, useStaticQuery } from "gatsby"
import React from "react"


export type SEOProps = {
    title?: string
}
export const SEO = ({ title }: SEOProps) => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        defaultTitle: title
                        description
                        siteUrl
                        image
                    }
                }
            }
        `
    )

    const {
        defaultTitle,
        description,
        siteUrl,
        image
    } = site.siteMetadata

    const seo = {
        title: title || defaultTitle,
        description,
        siteUrl,
        image
    }

    return (
        <>
            <title>{seo.title}</title>

            <meta property="og:title" content={seo.title} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:description" content={seo.description} />
            <meta property="og:url" content={seo.siteUrl} />
            <meta property="og:image" content={seo.image} />
            <meta property="twitter:card" content="summary_large_image"/>
        </>
    )
}