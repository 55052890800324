import { Box, Heading, Text, VStack } from "@chakra-ui/react"
import React from "react"
import Shell from "./shell"

const Footer = () => {
    return (
        <Box as='footer'>
            <Box bgGradient='linear(to-b, transparent, blackAlpha.600)' h='20em' w='full' />
            <Shell rounded='none' bgColor='blackAlpha.600'>
                <VStack maxW='container.sm' textAlign='center' mx='auto' gap={10} my={32}>
                    <Heading as='p'>This timeline is unofficial and the order of the movies and series might change in the future</Heading>
                    <Heading as='p' fontSize='2xl'>Movies and series are ordered chronologically for better viewing pleasure</Heading>
                    <Heading as='p' fontSize='2xl'>Some movies or series weren't included (Agatha: Covens of Chaos, Avengers: Secret Wars, Avengers: The Kang Dynasty, Echo, The Marvels) due to lack of the graphics</Heading>
                    <Heading as='p' fontSize='2xl'>Order is not final, it will be updated if new information is given out to public. The same goes to names of the future episodes</Heading>
                    <Heading as='p' display='block' fontSize='1xl'>Martini &copy; 2022</Heading>
                </VStack>
            </Shell>
        </Box>
    )
}

export default Footer