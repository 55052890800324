import { Center, HStack, SimpleGrid, Text, VStack } from "@chakra-ui/react"
import { useStaticQuery, graphql } from "gatsby"
import React, { ReactNode } from "react"
import showsReduce, { multiShowType, singleShowType } from "../../utils/showsReduce"
import Shell from "../shell"
import Movie from "./movie"
import Serie from "./serie"

const ShowList = () => {
    const groupped = showsReduce(useStaticQuery(graphql`
        query {
            shows: allMdx(sort: {order: ASC, fields: frontmatter___order}) {
                edges {
                    node {
                        details: frontmatter {
                            title
                            fullName
                            SE
                            release
                            order
                            accent
                            cover {
                                childImageSharp {
                                  gatsbyImageData(width: 500, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                                }
                              }
                        }
                    }
                }
            }
        }
    `).shows.edges)


    return (
        <Shell as={SimpleGrid} gridTemplateColumns={['1fr', '1fr', '1fr auto 1fr']} rowGap={[3, 3, 20]}>
            {groupped.map((g, i) => {
                if ((g as multiShowType).shows) {
                    return <Serie key={i} index={i} serie={g as multiShowType} />
                } else {
                    return <Movie key={i} index={i} movie={g as singleShowType} />
                }
            })}
        </Shell>
    )
}

export const ShowShell = ({ children, index }: { children: ReactNode, index: number }) => {
    return (
        <Shell
            w='fit-content'
            _odd={{ gridArea: ['auto / auto', 'auto / auto', `${index + 1} / 1 / span 2 / span 2`], ml: 'auto', mr: ['auto', 'auto', 1, 3], flexDirection: ['row', 'row', 'row-reverse'] }}
            _even={{ gridArea: ['auto / auto', 'auto / auto', `${index + 1} / 2 / span 2 / span 2`], mr: 'auto', ml: ['auto', 'auto', 1, 3], flexDirection: 'row' }}
            rounded='lg'
            bgColor='blackAlpha.300'
            mb='auto'
            as={HStack}
        >
            <Shell as={Center}>
                <Text fontSize='xl' fontWeight='semibold'>#{index+1}</Text>
            </Shell>

            <VStack>
                {children}
            </VStack>
        </Shell>
    )
}

export default ShowList