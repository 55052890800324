import { Heading } from "@chakra-ui/layout"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { ReactNode } from "react"
import '@fontsource/montserrat'
import Shell from "./shell"
import Footer from "./footer"

type LayoutProps = {
    children: ReactNode
}
const Layout = ({ children }: LayoutProps) => {
    const title = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `
    ).site.siteMetadata.title

    return (
        <>
            <Shell as='main'>
                <Heading as='h1' textAlign='center' my={5}>{title}</Heading>
                {children}
            </Shell>
            <Footer />
        </>
    )
}

export default Layout